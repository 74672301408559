import React, { useRef, Fragment } from "react";
import emailjs from "@emailjs/browser";
import { useDispatch } from "react-redux";

import classes from "./ContactUs.module.css";
import { alertActions } from "../../store/alert-slice";

const ContactUs = () => {
  const dispatch = useDispatch();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_1ygs0ht",
        "template_1mlb4se",
        form.current,
        "5C7j0X9JcVFrg21cO"
      )
      .then(
        (result) => {
          dispatch(
            alertActions.setState({
              message:
                "Email sent succssfully! We will get back to you as soon as possible.",
              status: "success",
            })
          );
        },
        (error) => {
          dispatch(
            alertActions.setState({
              message:
                "There was an error sending your email, please try again later or copy our email and email us direct from your mailbox",
              status: "error",
            })
          );
        }
      );

    e.target.reset();
  };

  return (
    <Fragment>
      <div className={classes.address}>
        <h2>Sean Wilberforce Chambers</h2>
        <address>8 New Square, Lincoln's Inn, London, WC2A 3QP</address>
        <address>(DX 311 Chancery Lane)</address>
        <p>Email → support@seanwilberforcechambers.com</p>
        <p>Tel → +44 (0)74 5120 6884</p>
      </div>
      <form className={classes.form} ref={form} onSubmit={sendEmail}>
        <label>Name</label>
        <input type="text" name="name" className={classes.input} />
        <label>Your Email</label>
        <input type="email" name="email" className={classes.input} />
        <label>Message</label>
        <textarea name="message" className={classes.textarea} />
        <button type="submit" value="Send">
          Send email
        </button>
      </form>
    </Fragment>
  );
};

export default ContactUs;
