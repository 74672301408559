import { Fragment } from 'react';
import RootLayout from './pages/RootLayout';
import { useSelector } from 'react-redux';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, Navigate } from 'react-router-dom';

import Body from './components/home-body/Body';
import PracticeAreas from './components/main/PracticeAreas';
import Industries from './components/main/Industries';
import AboutUs from './components/main/AboutUs';
import ContactUs from './components/main/ContactUs';
import AlertModal from './components/UI/AlertModal';
import Team from './components/main/Team';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<RootLayout />}>
            <Route index element={<Body />} />
            <Route path="/practiceAreas" element={<PracticeAreas />} />
            <Route path="/industries" element={<Industries />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/team" element={<Team />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Route>
    )
);

function App() {
    const showModal = useSelector((state) => state.alert.showModal);
    return (
        <Fragment>
            <RouterProvider router={router} />
            {showModal && <AlertModal />}
        </Fragment>
    );
}

export default App;
